// Generated by Framer (315fd46)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["nzxrWgMOO"];

const serializationHash = "framer-UjZuP"

const variantClassNames = {nzxrWgMOO: "framer-v-tt9245"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, width, ...props}) => { return {...props, VGWdGPNjf: link ?? props.VGWdGPNjf} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, VGWdGPNjf, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "nzxrWgMOO", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={VGWdGPNjf}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-tt9245", className, classNames)} framer-1h5e9tv`} data-framer-name={"blau"} layoutDependency={layoutDependency} layoutId={"nzxrWgMOO"} ref={ref ?? ref1} style={{...style}}><Image background={{alt: "bonding-studierendeninitiative e. V.", fit: "fit", intrinsicHeight: 90, intrinsicWidth: 300, pixelHeight: 90, pixelWidth: 300, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/GppamMSeNTfJPZrhVFOS3pQAf4.svg"}} className={"framer-1svsr5m"} layoutDependency={layoutDependency} layoutId={"lMTZbHZys"}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-UjZuP.framer-1h5e9tv, .framer-UjZuP .framer-1h5e9tv { display: block; }", ".framer-UjZuP.framer-tt9245 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-UjZuP .framer-1svsr5m { flex: none; height: 60px; max-width: 200px; position: relative; width: 200px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-UjZuP.framer-tt9245 { gap: 0px; } .framer-UjZuP.framer-tt9245 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-UjZuP.framer-tt9245 > :first-child { margin-top: 0px; } .framer-UjZuP.framer-tt9245 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 60
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"VGWdGPNjf":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerAkUPOCffB: React.ComponentType<Props> = withCSS(Component, css, "framer-UjZuP") as typeof Component;
export default FramerAkUPOCffB;

FramerAkUPOCffB.displayName = "Logo Institutsmarkt";

FramerAkUPOCffB.defaultProps = {height: 60, width: 200};

addPropertyControls(FramerAkUPOCffB, {VGWdGPNjf: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerAkUPOCffB, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})